.aboutCon {
    margin: auto;
    padding: 0;
}
.abtFirstH h5{
    color: #948685;
    font-family: "Open Sans", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 4.4px;
    text-transform: uppercase;
}
.abtSecH h5{
    color: #333;
    font-family: "Playfair Display", Sans-serif;
    font-size: 52px;
    font-weight: 400;
    line-height: 3rem;
}
.abtThirdH h5{
    color: #1C1C1C;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
.aboutBtn {
    margin-top: -0.5rem;
    width: 10rem;
    padding: 0.7rem 0 0.7rem 0;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-sm);
    background: black;
    color: white;
    font-size: 0.75rem;
    letter-spacing: -0.015rem;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    max-width: 100%;
}
.firstBack img {
    object-fit: fill;
    height: 400px;
    width: 100%;
}
.secBack img {
    object-fit: fill;
    height: 400px;
    width: 100%;
}
.scheduleCon {
    background-color: black;
}
.abtDiscover h5 {
    color: white;
    font-family: "Playfair Display", Sans-serif;
    font-size: 46px;
    font-weight: 400;
    line-height: 1em;
}
.scheduleBtn {
    margin-top: -0.5rem;
    width: 15rem;
    padding: 0.7rem 0 0.7rem 0;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-sm);
    background: white;
    color: black;
    font-size: 0.75rem;
    letter-spacing: -0.015rem;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    max-width: 100%;
}

@media (max-width: 768px) {
    .abtSecH h5 {
        font-size: 32px;
    }
    .abtDiscover h5 {
        font-size: 36px;
    }
}