.custom_img {
    object-fit: cover;
    height: 600px;
    max-width: 100%;
    opacity: 0.9;
  }

  .whoCon {
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .custom_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    height: 70px;
    border-radius: 5px;
  }
  .custom_caption h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }
  
  .carousel-item {
    position: relative;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
  }
  .whoBdy h5 {
    color: #1C1C1C;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .howFirstCon {
    background-color: white;
  }
  .howSecCon {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eef8f9;
  }
  .missionHead h5 {
    color: #F57D28;
    font-family: "Open Sans", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 4.4px;
  }
  .missionBdy {
    color: #1C1C1C;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .approachBdy h5 {
    color: white;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .thirdSecCon {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .coreSec {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cardH {
    text-transform: uppercase;
    color: black;
    font-size: 14px;
    font-weight: 600
  }
  .philoH {
    color: black;
    font-family: "Open Sans", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 4.4px;
    text-transform: uppercase;
  }
  .approachHead {
    color: #948685;
    font-family: "Open Sans", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 4.4px;
    text-transform: uppercase;
  }
  .approachBtn {
    margin-top: -0.5rem;
    width: 15rem;
    padding: 0.7rem 0 0.7rem 0;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-sm);
    background: white;
    color: black;
    font-size: 0.75rem;
    letter-spacing: -0.015rem;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    max-width: 100%;
  }
  .forthSecCon {
    background-color: black;
  }
  .OurCoreSec {    
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .staticCon {
    position: fixed;
    z-index: -1;
    height: 400px;
    top: 0%;
    width: 100%;
  }
  .staticCon img {
    width: 100%;
    opacity: 0.2;
  }

  @media (max-width: 768px) {
    .whoBdy h5 {
        line-height: 1.2rem;
    }
    .custom_img {
        height: 300px;
    }
    .custom_caption {
        width: 100%;
    }
    .missionBdy h5 {
        line-height: 1rem;
        font-size: 16px;
    }
    .approachBdy h5 {
        line-height: 1rem;
        font-size: 15px;
    }
    .OurCoreSec {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .coreSec {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .staticCon {
      position: absolute;
      top: 50%;
    }
    .staticCon img {
      height: 700px;
    }
  }
  
  @media (min-width: 819px) and (max-width: 1025px) {
    .staticCon {
      position: absolute;
      top: 58%;
      height: 900px;
    }
    .OurCoreSec {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  @media (min-width: 767px) and (max-width: 768px) {
    .coreSec {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .staticCon {
      position: absolute;
      top: 50%;
    }
    .staticCon img {
      height: 800px;
    }
  }