@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');

/* reset */
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
body {
  line-height: 1.5;
  font-family: 'Merriweather';
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  position: relative;
}
input, button, textarea, select {
  font: inherit;
}
picture, video, canvas {
  display: block;
  max-width: 100%;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Karla' ;
}
#root, #__next {
  isolation: isolate;
}

section .hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}