@font-face {
  font-family: 'Mozart';
  src: url('../../Fonts/mozart-regular_JZcV5/Mozart\ Regular/MozartRegular.otf');
  font-weight: bold;
}

/* for the new navbar*/
  .navbar {
    position: fixed;
    top: 0;
  }

  .navLink {
    text-transform: capitalize !important;
    text-decoration: none;
    color: black;
    width: 1rem;
  }

  .mx_auto h5{
    font-family: 'Mozart';
    src: url('../../Fonts/mozart-regular_JZcV5/Mozart\ Regular/MozartRegular.otf');
    font-size: 42px;
    font-weight: 600;
    color: white;
    text-transform: capitalize;
    padding: 7px, 0px, 7px, 0px;
  }

  .mx_auto a {
    text-decoration: none;
  }

  .canvasTitle h5 {
    font-family: 'Mozart';
    src: url('../../Fonts/mozart-regular_JZcV5/Mozart\ Regular/MozartRegular.otf');
    text-transform: capitalize;
    font-size: 35px;
  }

  .canvasBdy, .canvasTitle{
    padding: 0 3rem 0 3rem ;
  }
  
  .navbar-toggler {
    margin-left: auto;
  }

  .navLinkBtn {
    display: flex;
    margin-top: -0.5rem;
    width: 7rem;
    padding: 0.7rem 0 0.7rem 0;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-sm);
    background: black;
    color: white;
    font-size: 0.75rem;
    letter-spacing: -0.015rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0.25rem;
  }

  .navLinkBtn:hover {
    display: flex;
    width: 7rem;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 0.25rem;
    background: transparent;
    color: black;
    font-size: 0.75rem;
    letter-spacing: -0.015rem;
    font-weight: 600;
  }