.rootCom {
    position: relative;
    /*background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 46%, rgba(235,249,250,1) 75%, rgb(49, 206, 210) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 46%, rgba(235,249,250,1) 75%, rgb(49, 206, 210) 100%);
    background: linear-gradient(90deg, rgba(255,255,255,1) 46%, rgba(235,249,250,1) 75%, rgb(49, 206, 210) 100%);*/
}



.others {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
}