.heroAbtSec {
    background-color: #f1dfdb;
}
.heroAbtCon {
    height: 400px;
    background-color: #f1dfdb;
}
.firstH h5 {
    color: #948685;
    font-family: "Open Sans", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 1rem;
    letter-spacing: 4.4px;
    text-transform: uppercase;
}
.secH h5 {
    color: #1C1C1C;
    font-family: "Playfair Display", Sans-serif;
    font-size: 52px;
    font-weight: 400;
}
.thirdH h5 {
    color: #1C1C1C;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

@media (max-width: 768px) {
    .secH h5 {
        font-size: 32px;
    }
    .thirdH h5 {
        line-height: 1.2rem;
    }
}