.custom_img {
    object-fit: fill;
    object-position: bottom;
    height: 600px; /* Adjust this value to match your design requirements */
    max-width: 100%;
  }
  
  .custom_video {
    height: 600px;
    max-width: 100%;
    object-fit: cover;
    object-position: 30% 30%;
    margin: 0 auto;
    pointer-events: none;
    display: block;
  }

  .custom_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .custom_caption h5 {
    color: white;
    font-size: 60px;
    line-height: 84px;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  .custom_caption p {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
    color: white;
    font-weight: 200;
    text-transform: capitalize;
  }
  
  .carousel-item {
    position: relative;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
  }

  .package div img {
    width: 200px;
    height: 200px;
  }
  .package div h4 {
    font-family: 'Source Serif 4';
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: -2rem;
  }
  .package div p {
    font-size: 14px;
  }
  .cardCon div h5 {
    font-family: 'Source Serif 4';
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    font-weight: 300;
  }
  .cardCon div p {
    font-family: 'Source Serif 4';
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    font-style: normal;
    color: black;
  }
  .packCollection {
    background: rgb(237,202,192);
    background: radial-gradient(circle, rgb(233, 216, 212) 5%, rgba(255,255,255,1) 70%);
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection1 {
    background: rgb(28,47,51);
    background: radial-gradient(circle, rgb(206, 221, 224) 5%, rgba(255,255,255,1) 70%);
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection2 {
    background: rgb(237,202,192);
    background: radial-gradient(circle, rgb(233, 216, 212) 5%, rgba(255,255,255,1) 70%);
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection3 {
    background: rgb(28,47,51);
    background: radial-gradient(circle, rgb(206, 221, 224) 5%, rgba(255,255,255,1) 70%);
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection4 {
    background: rgb(237,202,192);
    background: radial-gradient(circle, rgb(233, 216, 212) 5%, rgba(255,255,255,1) 70%);
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection5 {
    background: rgb(28,47,51);
    background: radial-gradient(circle, rgb(206, 221, 224) 5%, rgba(255,255,255,1) 70%);
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection6 {
    background: rgb(237,202,192);
    background: radial-gradient(circle, rgb(233, 216, 212) 5%, rgba(255,255,255,1) 70%);
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection7 {
    background: rgb(237,202,192);
    background: radial-gradient(circle, rgb(233, 216, 212) 5%, rgba(255,255,255,1) 70%);
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .packCollection8 {
    background: rgb(237,202,192);
    background: radial-gradient(circle, rgb(233, 216, 212) 5%, rgba(255,255,255,1) 70%);
    height: 600px;
    display: flex;
    align-items: center;
    height: 1100px;
    justify-content: space-between;
  }
  .eachPack {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721381887/wedding/weddings2_mkkyev.jpg");
    background-size: contain;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack:hover {
    transform: scale(0.9);
  }
  .eachPack1 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721381886/wedding/weddings_3_mj6dzt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s ease-in-out;
    height: 450px;
    position: relative;
  }
  .eachPack1:hover {
    transform: scale(0.9);
  }
  .eachPack2 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721381882/wedding/8ee8704b44149a39749f222a36b51764_1_wjetgt.jpg");
    background-size: cover;
    background-position: 40% 40%;
    background-repeat: no-repeat;
    transition: transform 0.5s ease-in-out;
    height: 450px;
    position: relative;
  }
  .eachPack2:hover {
    transform: scale(0.9);
  }
  .eachPack3 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721381884/wedding/e231deec02502e174b5243e2d9be8e9d_fkm2dr.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack3:hover {
    transform: scale(0.9);
  }
  .eachPack4 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721381886/wedding/vow_renewal_ijhpi4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.5s ease-in-out;
    height: 450px;
    position: relative;
  }
  .eachPack4:hover {
    transform: scale(0.9);
  }
  .eachPack5 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721381884/wedding/outdoor_wedding_rzl3ty.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack5:hover {
    transform: scale(0.9);
  }
  .eachPack6 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721381884/wedding/destination_weddings_btvsjo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack6:hover {
    transform: scale(0.9);
  }
  .eachPack7 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485414/coorporate/seminar_i5ck1a.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack7:hover {
    transform: scale(0.9);
  }
  .eachPack8 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485414/coorporate/seminar2_j2ygye.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack8:hover {
    transform: scale(0.9);
  }
  .eachPack9 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485414/coorporate/seminar3_jcfilz.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack9:hover {
    transform: scale(0.9);
  }
  .eachPack10 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485412/coorporate/conference_clip1b.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack10:hover {
    transform: scale(0.9);
  }
  .eachPack11 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485413/coorporate/corporate_event_r33qff.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack11:hover {
    transform: scale(0.9);
  }
  .eachPack12 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485414/coorporate/gala_dinner2_dqliei.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack12:hover {
    transform: scale(0.9);
  }
  .eachPack13 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485413/coorporate/gala_dinner_fyujil.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack13:hover {
    transform: scale(0.9);
  }
  .eachPack14 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721490282/Intimate/intimate5_vfw8te.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack14:hover {
    transform: scale(0.9);
  }
  .eachPack15 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721490283/Intimate/surprise_birthday_jylkl5.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack15:hover {
    transform: scale(0.9);
  }
  .eachPack16 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721490286/Intimate/intimate_event_2_b45z5b.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack16:hover {
    transform: scale(0.9);
  }
  .eachPack17 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721490286/Intimate/intimate_event_bv8ctt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack17:hover {
    transform: scale(0.9);
  }
  .eachPack18 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721490279/Intimate/bridal_shower2_nd98w7.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 30% 30%;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack18:hover {
    transform: scale(0.9);
  }
  .eachPack19 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721490280/Intimate/gender_reveal_zfcnps.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack19:hover {
    transform: scale(0.9);
  }
  .eachPack20 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721490288/Intimate/proposal_qti85b.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack20:hover {
    transform: scale(0.9);
  }
  .eachPack21 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995215/social/birthdays1_yznrov.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack21:hover {
    transform: scale(0.9);
  }
  .eachPack22 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995217/social/birthdays2_v5gbah.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack22:hover {
    transform: scale(0.9);
  }
  .eachPack23 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995210/social/birthday3_bnmsmd.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack23:hover {
    transform: scale(0.9);
  }
  .eachPack24 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995212/social/birthday4_po5459.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack24:hover {
    transform: scale(0.9);
  }
  .eachPack25 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995211/social/child_dedication_cmxdol.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack25:hover {
    transform: scale(0.9);
  }
  .eachPack26 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995216/social/child_dedication1_ckxmnw.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack26:hover {
    transform: scale(0.9);
  }
  .eachPack28 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995219/social/childrens_birthday1_egn3wg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack28:hover {
    transform: scale(0.9);
  }
  .eachPack29 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995215/social/childrens_birthday_sorq3d.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack29:hover {
    transform: scale(0.9);
  }
  .eachPack27 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995209/social/baby_shower_jrfewc.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack27:hover {
    transform: scale(0.9);
  }
  .eachPack30 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995220/social/birthdays_g9zb6m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack30:hover {
    transform: scale(0.9);
  }
  .eachPack31 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1720995220/social/social_events1_fliaoo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack31:hover {
    transform: scale(0.9);
  }
  .eachPack32 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721546365/design/stagedecor1_gorvhm.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack32:hover {
    transform: scale(0.9);
  }
  .eachPack33 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721546365/design/newq_cfhlk3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack33:hover {
    transform: scale(0.9);
  }
  .eachPack34 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721546361/design/decq_nps37g.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack34:hover {
    transform: scale(0.9);
  }
  .eachPack35 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721546363/design/stagedecor_panurd.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack35:hover {
    transform: scale(0.9);
  }
  .eachPack36 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721546365/design/tablesettings_gxpu0z.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack36:hover {
    transform: scale(0.9);
  }
  .eachPack37 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721546364/design/sitting_and_table_decor_wfu1m4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack37:hover {
    transform: scale(0.9);
  }
  .eachPack38 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721546368/design/wedding_reception_decor_ltoig2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack38:hover {
    transform: scale(0.9);
  }
  .eachPack39 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721485415/coorporate/product_launch_h27jii.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 450px;
    transition: transform 0.5s ease-in-out;
    position: relative;
  }
  .eachPack39:hover {
    transform: scale(0.9);
  }
  .bdyCap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }  
  .bdyCap h4 {
    font-family: 'Source Serif 4';
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    padding: 0;
    color: white;
    text-transform: capitalize;
  }
  .wedCon {
    position: relative;
  }
  .wedOther {
    position: absolute;
    top: 0;
  }

  
  @media (max-width: 768px) {
    .custom_img {
      height: 400px !important;
    }
    .custom_video {
      height: 400px;
      max-width: 100%;
    }
    .custom_caption {
      width: 80%;
    }
    .custom_caption h5 {
      color: white;
      font-size: 40px;
      line-height: 44px;
      letter-spacing: normal;
      text-transform: uppercase;
    }
    .custom_caption p {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      color: white;
      font-weight: 200;
      text-transform: capitalize;
    }
    .wedding {
      height: 800px;
    }
    .packageSec {
      height: 800px;
    }
    .eachPack, .eachPack1, .eachPack10, .eachPack11, .eachPack12, .eachPack13, .eachPack14, .eachPack15, .eachPack16 {
      width: 100%;
      height: 300px;
    }
    .eachPack2, .eachPack3,.eachPack4, .eachPack5, .eachPack6, .eachPack7, .eachPack8, .eachPack9, .eachPack17, .eachPack18, .eachPack19{
      width: 100%;
      height: 300px;
    }
    .eachPack20, .eachPack21, .eachPack22, .eachPack23, .eachPack24, .eachPack25, .eachPack26, .eachPack27, .eachPack28 {
      width: 100%;
      height: 300px;
    }
    .eachPack29, .eachPack30, .eachPack31, .eachPack32, .eachPack33, .eachPack34, .eachPack35, .eachPack36, .eachPack37, .eachPack38{
      width: 100%;
      height: 300px;
    }
    .eachPack39 {
      width: 100%;
      height: 300px;
    }
    .packCollection1, .packCollection2, .packCollection3, .packCollection4 {
      height: 1200px;
    }
    .packCollection {
      height: 1000px;
    }
    .packCollection5 {
      height: 700px;
    }
    .packCollection6 {
      height: 500px;
    }
    .packCollection7 {
      height: 1300px;
    }
    .packCollection8 {
      height: 2000px;
    }
  }
  