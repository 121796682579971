@font-face {
  font-family: saonara;
  src: url("../../Fonts/made_saonara/MADE\ SAONARA\ PERSONAL\ USE.otf");
}

.other {
  position: absolute;
  top: 0;
}

.ourCon {
  position: relative;
}

.custom_img {
    object-fit: fill;
    height: 410px;
    max-width: 100%;
    opacity: 0.9;
  }
.custom_img1 {
    object-fit: cover;
    height: 410px;
    opacity: 0.9;
  }

  .imgContainer {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721560234/management_r6wqsy.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 420px;
  }
  .imgContainer1 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721560234/planning_dcuhbr.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 400px;
    height: 420px;
  }
  .imgContainer2 {
    background-image: url("https://res.cloudinary.com/dglpy94yq/image/upload/v1721560234/management2_dsns8o.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 400px;
    height: 420px;
  }

  .custom_video {
    height: 600px;
    max-width: 100%;
    object-fit: cover;
    object-position: 30% 30%;
    pointer-events: none;
    margin: 0 auto;
    display: block;
  }
  
  .custom_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    height: 70px;
    border-radius: 5px;
  }

  .custom_caption h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }

  .vertline {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 30px;
  }
  
  .line {
    width: 3px;
    height: 100%;
    background-color: black;
    opacity: 0.5;
  }

  .abtPro h5 {
    font-family: "saonara";
    src: url('../../Fonts/made_saonara/MADE\ SAONARA\ PERSONAL\ USE.otf');
    font-size: 16px;
    text-transform: uppercase;
    line-height: normal;
    letter-spacing: normal;
  }

  .abtPro p {
    font-family: "Source Serif 4";
    font-size: 25px;
    line-height: 35px;
    letter-spacing: normal;
    font-weight: 300;
  }

  .simpCon {
    background-color: #edcac0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }

  .simpCon1 {
    background-color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }

  .simpCon2 {
    background-color: #c7c7c7;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }

  .simpTitle1 {
    margin-left: 3rem;
  }

  .horizntaline {
    width: 25%;
    height: 3px;
    background-color: white;
  }

  .horizntaline3 {
    width: 25%;
    height: 3px;
    background-color: white;
    margin-left: 3rem;
  }

  .simpTitle h5 {
    font-family: "Source Serif 4" !important;
    font-size: 40px;
    line-height: 56px;
    color: white;
    text-transform: uppercase;
    letter-spacing: normal;
    font-weight: 600;
  }

  .simpTitle h4 {
    font-family: "Source Serif 4" !important;
    font-size: 60px !important;
    line-height: 80px;
    letter-spacing: normal;
    text-transform: uppercase;
    color: black;
    margin-left: 1rem;
    font-weight: 600;
  }

  .simpTitle1 h5 {
    font-family: "Source Serif 4" !important;
    font-size: 40px;
    line-height: 56px;
    color: #edd3ce;
    text-transform: uppercase;
    letter-spacing: normal;
    font-weight: 600;
  }

  .simpTitle1 h4 {
    font-family: "Source Serif 4" !important;
    font-size: 60px;
    line-height: 100px;
    letter-spacing: normal;
    text-transform: uppercase;
    color: black;
    margin-left: 1rem;
    font-weight: 600;
  }

  .simpTitle1 p {
    font-family: "Fraunces";
    font-size: 200px;
    line-height: 280px;
    font-weight: 300;
    color: #edd3ce;
    margin-top: -4rem;
    margin-left: 6rem;
  }

  .simpTitle3 h5 {
    font-family: "Source Serif 4" !important;
    font-size: 40px;
    line-height: 56px;
    color: #edd3ce;
    text-transform: uppercase;
    letter-spacing: normal;
    font-weight: 600;
  }

  .simpTitle3 h4 {
    font-family: "Source Serif 4" !important;
    font-size: 80px;
    line-height: 112px;
    letter-spacing: normal;
    text-transform: uppercase;
    color: black;
    margin-left: 3rem;
    font-weight: 600;
  }

  .simpTitle3 p {
    font-family: "Fraunces";
    font-size: 200px;
    line-height: 280px;
    font-weight: 300;
    color: #edd3ce;
    margin-top: -4rem;
    margin-left: 6rem;
  }

  .simpTitle3 {
    margin-left: 3rem;
  }

  .simpTitle2 h5 {
    font-family: "Source Serif 4" !important;
    font-size: 50px;
    line-height: 95px;
    color: black;
    text-transform: uppercase;
    letter-spacing: normal;
    font-weight: 600;
  }

  .simpTitle2 h4 {
    font-family: "Source Serif 4" !important;
    font-size: 60px;
    line-height: 112px;
    letter-spacing: normal;
    text-transform: uppercase;
    color: black;
    margin-left: 3rem;
    font-weight: 600;
  }

  .simpTitle2 p {
    font-family: "Fraunces";
    font-size: 200px;
    line-height: 280px;
    font-weight: 300;
    color: #edd3ce;
    margin-top: -4rem;
    margin-left: 6rem;
  }

  .simpTitle p {
    font-family: "Fraunces";
    font-size: 200px;
    line-height: 280px;
    font-weight: 300;
    color: #edd3ce;
    margin-top: -4rem;
    margin-left: 6rem;
  }

  .simpDisc h5 {
    font-family: "Source Serif 4";
    font-size: 20px;
    line-height: 28px;
    font-style: italic;
    font-weight: 400;
  }

  .disc {
    background-color: #f8f7f4;
  }


  @media (max-width: 768px) {
    .simpTitle h4, .simpTitle1 h4, .simpTitle2 h4, .simpTitle3 h4 {
      margin-left: 0;
      margin-top: -3rem;
    }
    .simpTitle h5, .simpTitle1 h5, .simpTitle2 h5, .simpTitle3 h5 {
      margin-left: 0;
      margin-top: -6rem;
    }
    .simpTitle p, .simpTitle1 p, .simpTitle2 p, .simpTitle3 p {
      margin-left: 0;
      margin-top: -4rem;
      font-size: 70px;
    }
    .simpTitle1, .simpTitle3 {
      margin-left: 0;
    }
    .custom_caption {
      width: 100%;
    }
    .custom_img {
      height: 400px;
    }
    .custom_video {
      max-width: 100%;
      height: 450px;
    }
    .imgContainer {
      width: 350px;
    }
    .horizntaline, .horizntaline3 {
      margin-left: 0;
    }
    .simpCon, .simpCon1, .simpCon2 {
      height: 800px;
    }
  }