.custom_img {
    object-fit: fill;
    height: 600px; /* Adjust this value to match your design requirements */
    max-width: 100%;
    opacity: 0.9;
  }

  .workCon {
    position: relative;
   }

  .mainCon {
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .custom_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    height: 70px;
    border-radius: 5px;
  }
  .custom_caption_design {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    height: 160px;
    border-radius: 5px;
  }
  .custom_caption_design h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }
  .custom_caption_birthday {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    height: 120px;
    border-radius: 5px;
  }
  .custom_caption_birthday h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }
  .custom_caption_corporate {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    height: 160px;
    border-radius: 5px;
  }
  .custom_caption_corporate h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }
  .custom_caption_proposal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    height: 160px;
    border-radius: 5px;
  }
  .custom_caption_proposal h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }

  .custom_caption h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }
  
  .carousel-item {
    position: relative;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
  }
  .howWeCon {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .howWe h5 {
    color: #1C1C1C;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .ourServiceCon {
    background-color: #eef8f9;
  }
  .ourService h5 {
    color: #2b3739;
    font-family: "Open Sans", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 4.4px;
    text-transform: uppercase; 
  }
  .serveCard {
    background-color: #44585b;
  }
  .cardHead {
    color: white;
    text-transform: uppercase;
  }
  .cardH {
    color: white;
  }
  .cardText {
    color: white;
  }
  .space {
    height: 300px;
  }
  .fixedBack {
    position: fixed;
    top: 10%;
    z-index: -1;
    width: 100%;
  }
  .fixedBack img {
    width: 100%;
    opacity: 0.7;
  }
  .blur {
    filter: blur(5px);
  }
  .wedSecCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .custom_caption_birthday h5, .custom_caption_corporate h5, .custom_caption_design h5, .custom_caption_proposal h5 {
        font-size: 20px;
    }
    .custom_caption {
      width: 100%;
    }
    .custom_caption_birthday {
        width: 70%;
        height: 70px;
    }
    .custom_caption_corporate {
        width: 70%;
        height: 90px;
    }
    .custom_caption_proposal {
        width: 70%;
        height: 75px;
    }
    .custom_caption_design {
        width: 70%;
        height: 70px;
    }
    .wedSecCon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .custom_img {
      height: 400px;
    }
  }

  @media (min-width: 767px) and (max-width: 1025px) {
    .wedSecCon {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .custom_caption_birthday h5, .custom_caption_corporate h5, .custom_caption_design h5, .custom_caption_proposal h5 {
        font-size: 30px;
    }
    .custom_caption {
      width: 100%;
    }
    .custom_caption_birthday {
        width: 70%;
        height: 84px;
    }
    .custom_caption_corporate {
        width: 70%;
        height: 80px;
    }
    .custom_caption_proposal {
        width: 70%;
        height: 85px;
    }
    .custom_caption_design {
        width: 70%;
        height: 100px;
    }
    .cardHead {
        font-size: 15px;
    }
  }