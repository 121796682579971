.contactHead {
    font-family: 'Libre Baskerville';
    font-size: 1.5rem;
    font-weight: 800;
    font-style: normal;
    line-height: 120%;
    color: #161617;
    word-spacing: 0.5rem;
    letter-spacing: -0.04rem;
    text-transform: uppercase;
    padding: 0rem 1rem 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}
.nameLabel {
    font-family: 'Merriweather' !important;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    line-height: 120%;
    color: black;
    word-spacing: 0.1rem;
    letter-spacing: -0.04rem;
    text-transform: capitalize;
    padding: 0rem 1rem 0.5rem 1rem;
    justify-content: center;
    align-items: center;
}
.input, .textArea {
  background-clip: padding-box;
  background-image: none;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  color: #495057;
  display: block;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
  max-width: 100%;
  padding: 6px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%;
}
.contactBtn {
    display: flex;
    margin-top: -0.5rem;
    width: 100%;
    padding: 0.7rem 0 0.7rem 0;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-sm);
    background: black;
    color: white;
    font-size: 0.75rem;
    letter-spacing: -0.015rem;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    max-width: 100%;
}
.contactBtn button {
    border: none;
}

input, select, textarea:focus {
    outline: none;
}
.blur {
    filter: blur(5px);
}
.custom_img {
    object-fit: cover;
    height: 300px; /* Adjust this value to match your design requirements */
    max-width: 100%;
    opacity: 0.9;
  }
  
  .custom_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: to make the caption more readable */
    padding: 10px;
    height: 70px;
    border-radius: 5px;
  }
  .custom_caption h5 {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
  }
  
  .carousel-item {
    position: relative;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
  }
  .contactLogo img {
    width: 120px;
  }
  .firstColContact h5 {
    color: #1C1C1C;
    font-family: "Open Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .contactEmail span {
    font-family: "Open Sans", Sans-serif;
    font-size: 14px;
    color: #999;
  }
  .contactEmail h5 {
    font-family: "Open Sans", Sans-serif;
    font-size: 15px;
  }


  @media (max-width: 768px) {
    .contactLogo {
        width: 100px;
    }
    .custom_caption h5{
      font-size: 20px;
    }
  }