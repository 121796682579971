.footCon {
    background-color: #eef8f9;
}
.firstCol h5 {
    font-size: 16px;
    color: #2b3739;
}
.secCol a {
    font-size: 24px;
    color: #2b3739;
    text-decoration: none;
    gap: 1rem;
    padding-left: 1rem;
}

@media (max-width: 768px) {
    .firstCol {
        text-align: center;
    }
    .secCol {
        text-align: center;
    }
}