.custom_img {
    object-fit: fill;
    object-position: bottom;
    height: 600px; /* Adjust this value to match your design requirements */
    max-width: 100%;
  }
  
  .custom_video {
    width: 100%;
    object-fit: cover;
    height: 600px;
    pointer-events: none;
  }

  .custom_caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .custom_caption h5 {
    color: white;
    font-size: 60px;
    line-height: 84px;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  .custom_caption p {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
    color: white;
    font-weight: 200;
    text-transform: capitalize;
  }
  
  .carousel-item {
    position: relative;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    .custom_img {
      height: 400px !important;
    }
    .custom_video {
      height: 400px;
    }
    .custom_caption {
      width: 80%;
    }
    .custom_caption h5 {
      color: white;
      font-size: 40px;
      line-height: 44px;
      letter-spacing: normal;
      text-transform: uppercase;
    }
    .custom_caption p {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      color: white;
      font-weight: 200;
      text-transform: capitalize;
    }
  }
  